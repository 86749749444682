$(function() {
  function throttle(func, wait, options) {
    var timeout, context, args, result;
    var previous = 0;
    if (!options) options = {};

    var later = function() {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };

    var throttled = function() {
      var _now = Date.now();
      if (!previous && options.leading === false) previous = _now;
      var remaining = wait - (_now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = _now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };

    throttled.cancel = function() {
      clearTimeout(timeout);
      previous = 0;
      timeout = context = args = null;
    };

    return throttled;
  }

  function toggleLocationsContainer(results) {
    $('.autocomplete-results').show();
    $('.locations-container').hide();

    $('.locations').empty()
    if(results.locations.length > 0) {
      $('.locations-container').show();
    } else {
      $('.locations-container').hide();
    }
  }

  var displayResults = function(results) {
    if(results.locations.length > 0 || results.venues.length > 0) {
      $('venues-container').hide();
      toggleLocationsContainer(results)
      var amenitiesUrl = ""
      var categoriesUrl = ""
      var amenities = Array.from(document.getElementsByClassName('amenity-filter'))
      amenities.forEach((amenity => {
        if (amenity.checked) {
          amenitiesUrl = amenitiesUrl + `filters[]=${amenity.value}&`
        }
      }))
      var categories = Array.from(document.getElementsByClassName('category-filter'))
      categories.forEach((category => {
        if (category.checked) {
          categoriesUrl = categoriesUrl + `categories[]=${category.value}&`
        }
      }))
      var capacity = $('#capacity').val()
      results.locations.forEach(function(location) {
        var locationHtml = `
          <p class="location-result flex bg-white-0 shadow rounded p-3 mb-2 hover:cursor-pointer" data-turbolinks="false" data-location-id="${location.id}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            ${location.name}
          </p>
        `
        $('.locations').append(locationHtml);
      });

      $('#venues').empty();
      if(results.venues && results.venues.length > 0) {
        $('#venues-container').show();
        results.venues.forEach(function(venue) {
          var venueHtml = `
            <a href="/coworking-space/${parameterize(venue.city)}${venue.area ? `/${parameterize(venue.area)}` : ''}/${venue.slug}" class="flex bg-white-0 shadow rounded p-3 mb-2" data-turbolinks="false">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              ${venue.name}
            </a>
          `
          $('#venues').append(venueHtml);
        });
      } else {
        $('#venues-container').hide();
      }
    } else { 
      $('.autocomplete-results').hide();
    }
  }

  var displayLocations = function(results) {
    if(results.locations.length > 0) {
      toggleLocationsContainer(results)
      results.locations.forEach(function(location) {
        var locationButton = `
          <p class="location-result flex bg-white-0 shadow rounded p-3 mb-2 hover:cursor-pointer" data-turbolinks="false" data-location-id="${location.id}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            ${location.name}
          </p>
        `
        $('.locations').append(locationButton);
      });
    } else {
      $('.autocomplete-results').hide();
    }
  }

  function fetchLocationsOnInput(input) {
    var cache = {};
    var requestCounter = 0;
    var displayCounter = 0;

    input.on('keyup', throttle(function(e) {
      var query = input.val();
      if (query.length > 2) {
        if (cache[query]) {
          input.selector === '#search-autocomplete' ? displayResults(cache[query]) : displayLocations(cache[query]);
        } else {
          var requestId = ++requestCounter;
          $.getJSON('/search/autocomplete?query=' + query, function(data) {
            cache[query] = data;
            if (requestId >= displayCounter) {
              input.selector === '#search-autocomplete' ? displayResults(data) : displayLocations(data);
              displayCounter = requestId;
            }
          });
          requestCounter++;
        }
      } else {
        $('.autocomplete-results').hide();
      }
    }, 200));
  }

  var searchInput = $('#search-autocomplete');
  var ftoSearchInput = $('#fto-search-autocomplete')
  var enquiryInput = $('#office_search_location_preference')
  var currentOfficeInput = $('#company_current_area')

  if (searchInput) {
    fetchLocationsOnInput(searchInput)
  }

  if (ftoSearchInput) {
    fetchLocationsOnInput(ftoSearchInput)
  }

  if (enquiryInput) {
    fetchLocationsOnInput(enquiryInput)
  }

  if (currentOfficeInput) {
    fetchLocationsOnInput(currentOfficeInput)
  }

  function parameterize(string) {
    var result = string.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    result = result.replace(/^-+|-+$/g, '');
    return result;
  }
});
